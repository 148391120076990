<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css">
  <link rel="stylesheet" href="https://4fast.lol/snow.css" />

  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="snow"></div>
  <div class="dashboard p-4">
    
    <div class="text-center" >
      <h1 class="text-2xl dark:text-white text-gray-800 font-semibold"> Live Status</h1>
    <button class="flex-auto cursor-default p-4" v-for="website in websites" :key="website.name">
    <div class="flex rounded-md">
      <div class="text-center lg:block md:block hidden">
        <h2 class="dark:text-white text-gray-800">{{ website.name }}</h2>
        <!-- <span v-if="website.status === 'Online'" class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
          <span class="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
          Online
      </span> -->
        <p v-if="website.status === 'Online'" style="color: #339966;" class="text-xl">
          {{ website.status }}
        </p>
        <!-- <span v-if="website.status === 'Unstable'" style="background-color: #e6ae06; color: white;" class="inline-flex items-center text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span class="w-2 h-2 me-1 bg-yellow-300 rounded-full"></span>
          Unstable
      </span> -->
        <p v-if="website.status === 'Unstable'" style="color: #e6ae06;" class="text-xl">
          {{ website.status }}
        </p>
        <!-- <span v-if="website.status === 'Offline'" style="background-color: #ef4444; color: #e63900; color: white;" class="inline-flex items-center text-xs font-medium px-2.5 py-0.5 rounded-full">
          <span class="w-2 h-2 me-1 bg-red-600 rounded-full"></span>
          Offline
      </span> -->
        <p v-if="website.status === 'Offline'" style="color: #e63900;" class="text-xl">
          {{ website.status }}
        </p>
      </div>
    </div>
</button>
</div>

    <alert v-if="offlineServers.length > 0" class="bg-red-500 text-white">
      <template v-slot:icon>
        <Icon icon="ic:outline-dangerous" />
      </template>
      <template v-slot:heading> Offline Server Detected! </template>
      <template v-slot:content>
        <!-- Display the names of all offline servers -->
        {{ offlineServers.join(', ') }} | server were restarted or shutdowned due to performance issues and/or update.
      </template>
    </alert>
    <br>
    <alert v-if="fullServers.length > 0" class="bg-orange-500 text-white">
      <template  v-slot:icon>
        <Icon icon="clarity:warning-line" />
      </template>
      <template v-slot:heading> Server is full! </template>
      <template v-slot:content> {{ fullServers.join(', ') }} | Woah server is full!</template>
    </alert>

    <!-- end nav -->
    <div class="mt-2 w-full">
      <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
        <div>
          <p class="uppercase text-2xl dark:text-white text-gray-800 font-semibold">Overview</p>
        </div>
        <!-- <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
            Dashboard
          </h1> -->
        <!-- <div class="flex gap-2"><button
            class="bg-white dark:bg-gray-800 hover:border-gray-200 dark:hover:bg-gray-700 dark:text-white dark:border-gray-700 border rounded py-2 px-5"
          >
            New view
          </button><button
            class="bg-primary border flex gap-2 text-white hover:bg-primary/80 dark:border-gray-700 rounded py-3 px-5"
          ><span class="icon text-2xl"><Icon icon="ic:twotone-plus" /></span><span class="text"> Create New Report</span></button></div> -->
      </div>
    </div>
    
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-2 mt-5">
      <!-- card  -->
      <div v-for="server in servers" :key="server.id" class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 flex">
        <div class="flex-auto p-4">

          <div v-if="server.queue !== 0 " class="flex items-center mb-2 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
            <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span class="sr-only">Info</span>
            <div>
              <strong class="text-s leading-6 font-semi">{{ server.name }}</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>is full now and there is {{ server.queue }} waiting for connect to server.
            </div>
          </div>
          
          <Modal
          :title="`Server Details: ${server.name} 🚛`"
          btnText="⚙️"
          :backdrop="false"
        >
          <template v-slot:body>
            <div>
              <p>
                - Server IP/PORT: {{ server.ip }}:{{ server.port }} 🌐<br>
                - Server Shortname: {{ server.shortname }} 🏷️<br>
                
                <p v-if="server.idprefix !== null && server.idprefix !== undefined">
                  - Server ID Prefix: {{ server.idprefix }} 🆔<br>
                </p>
              
                <p v-if="Boolean(server.event) === true">
                  - Server Type: Event Server 🎉<br>
                  - Event Type: Community Hosted 🤝<br>
                </p>
              
                <p v-else>
                  <p v-if="Boolean(server.specialEvent && server.event) === true">
                    - Event Type: TruckersMP Hosted "{{ server.name }}" Event 🌐🎉<br>
                  </p>
                  - Server Type: Official Server ⚙️<br>
                </p>
              </p>
              
              <p>
                - Players: {{ server.players }} 👥<br>
                - Max Players: {{ server.maxplayers }} 🚀<br>
                - Status: {{ server.online ? 'Online' : 'Offline' }} {{ server.online ? '🟢' : '🔴' }}<br>
                - Percentage Full: {{ ((server.players / server.maxplayers) * 100).toFixed(2) }}% 📊<br>
                - Queue: {{ server.queue }} 🚶‍♂️<br>
                - Speed Limiter: <span :style="{ color: server.speedlimiter ? 'green' : 'red' }">{{ server.speedlimiter ? 'Enabled' : 'Disabled' }}</span> 🚥<br>
                - Collisions: <span :style="{ color: server.collisions ? 'green' : 'red' }">{{ server.collisions ? 'Enabled' : 'Disabled' }}</span> 💥<br>
                - Cars for Players: <span :style="{ color: server.carsforplayers ? 'green' : 'red' }">{{ server.carsforplayers ? 'Enabled' : 'Disabled' }}</span> 🚗<br>
                - Police Cars for Players: <span :style="{ color: server.policecarsforplayers ? 'green' : 'red' }">{{ server.policecarsforplayers ? 'Enabled' : 'Disabled' }}</span> 🚓<br>
                - AFK Enabled: <span :style="{ color: server.afkenabled ? 'green' : 'red' }">{{ server.afkenabled ? 'Yes' : 'No' }}</span> ⏳<br>
                - ProMods: <span v-if="server.game !== 'ATS'" :style="{ color: server.promods ? 'green' : 'red' }">{{ server.promods ? 'Enabled' : 'Disabled' }}</span> 🗺️<br>
              </p>              
            </div>
          </template>
        </Modal>
        <p style="float: right;" v-if="Boolean(server.speedlimiter) === true" >
          <img style="width: 27px;
          height: 27px;" src="https://stats.truckersmp.com/static/truckersmp/speed_110.png">
        </p>

          <span v-if="server.specialEvent" class="bg-red-700 rounded-md py-1 px-2 text-white text-xs">TruckersMP Official Event</span>
          <!-- <span class="bg-orange-600 rounded-full text-xs py-1 px-2 text-white">{{ server.percent }}</span> -->
          <h5 class="uppercase font-bold text-2xl dark:text-white text-gray-800">{{ server.name }} <span style="color: #ffffff;" class="font-normal text-xl"> ({{ server.shortname }})</span></h5> 
          <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
              <div v-if="Boolean(server.online) === true">
                <h5 style="color: #4caf50;" class="uppercase font-bold text-xl">ONLINE</h5>
              </div>
              <div v-else>
                <h5 style="color: #ff0000;" class="uppercase font-bold text-xl">OFFLINE</h5>
              </div>
              
              <span class="font-bold text-2xl">
              <i v-if="server.arrow === 'down'" style="color: red;" class="bi bi-caret-down-fill"></i>
              <i v-if="server.arrow === 'same'" style="color: yellow;" class="bi bi-caret-left-fill"></i>
              <i v-if="server.arrow === 'up'" style="color: green;" class="bi bi-caret-up-fill"></i></span>
              <span class="dark:text-white text-gray-800 font-bold text-2xl">
                 {{ server.players }}
              </span>
              <span style="color: #777;" class="text-2xl">/{{ server.maxplayers }}</span>
            
            </div>
            <div class="relative w-auto pl-4 flex-initial">
              <div class="text-white p-3 text-center inline-flex items-center justify-center shadow-lg rounded-full bg-transparent">
                <div>
                  <div v-if="server.promods === true && server.game !== 'ATS'">
                    <img src="https://stats.truckersmp.com/static/truckersmp/promods.png">
                  </div>
                <div v-else-if="server.game === 'ETS2'">
                  <img src="https://stats.truckersmp.com/static/truckersmp/ets2.png">
                </div>
                <div v-else>
                  <img src="https://stats.truckersmp.com/static/truckersmp/ats.png">
                </div>
              </div>
              
              </div>
            </div> 
          </div>
          <p v-if="server.players > 0.01" class="text-sm dark:text-white text-gray-800 mt-4">Server is 
            <span class="text-red-500 mr-2">
              {{ ((server.players / server.maxplayers) * 100).toFixed(2) }}%</span>
            <span class="whitespace-nowrap">full</span>
          </p>
          <p v-else class="text-sm dark:text-white text-gray-800 mt-4">Server is 
          <span class="whitespace-nowrap">empty.</span>
        </p>
          <p class="text-sm dark:text-white text-gray-800 mt-4">
            <span class="text-green-500 mr-2">
              {{ server.queue }} </span>
            <span class="whitespace-nowrap">waiting in queue</span>
          </p>
        </div>
      </div>
      <!-- end card --> 
    </div>
    <!-- end wrapper card -->
  <!--  <div class="mt-2 lg:flex block lg:gap-2 relative">
      <div class="bg-white dark:bg-gray-800 p-0 lg:w-3/4 w-full rounded-md box-border border dark:border-gray-700">
        <div class="p-5 flex justify-between">
          <div>
            <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200"> SALES </h2>
            <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200"> 72% </h1>
            <p class="text-gray-400 font-lexend font-normal"> your sales chart per-years </p>
          </div>
          <div class="flex gap-5">
            <span class="">
              <h2 class="text-red-500 flex">
                <span class="mr-2"> 15.9% </span>
                <span>
                  <Icon icon="akar-icons:arrow-down" />
                </span>
              </h2>
            </span>
            <span class="">
              <h2 class="text-green-500 flex">
                <span class="mr-2"> 87.9% </span>
                <span>
                  <Icon icon="akar-icons:arrow-up" />
                </span>
              </h2>
            </span>
          </div>
        </div>
        <apexchart width="100%" height="260" type="area" :options="optionsArea" :series="seriesArea" :sparkline="{
            enabled: true,
          }">
        </apexchart>
        <br />
        <hr />
        <div class="wrapper-button p-5 flex justify-between mt-3">
          <select name="" id="" class="dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-700 border max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400">
            <option value="">Last 7 years</option>
          </select>
          <button class="uppercase border-b border-red-600 text-red-600"> Sales Report </button>
        </div>
      </div>
       <div class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4 lg:w-2/4 border dark:border-gray-700 rounded-md w-full">
        <div class="">
          <h2 class="text-lg font-semibold dark:text-gray-200"> ONLINE PLAYERS C-D </h2>
          <p class="text-gray-400">This is a list of online players near popular cities.</p>
        </div>
        <perfect-scrollbar class="divide-y h-96 mt-5 dark:divide-gray-700">
          <div class="p-3 w-full" v-for="(player, index) in area" :key="index">
            <div class="flex gap-5">
              <div>
                <img class="w-14 rounded-md" src="../assets/img/user1.png" alt="" />
              </div>
              <div class="mt-1">
                <h2 class="dark:text-gray-200">{{ player.Name }}</h2>
                <p class="text-sm dark:text-gray-500 text-gray-400"> Active now </p>
                            <p v-if="player.MpId">
              <a :href="'https://truckersmp.krashnz.com/player/' + player.MpId" target="_blank">Player Details</a>
            </p>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div> -->

    <!-- <p>Players Online: {{ current.current_players }}</p>
    <p>ETS2 Players: {{ current.ets2_current_players }}</p>
    <p>ATS Players: {{ current.ats_current_players }}</p>
    <p>Game Time: {{ current.game_time }}</p>
    <p>Registered Players: {{ current.registered_players }}</p> -->
    
    <div class="container lg:max-w-8xl mx-auto text-center mt-40">
      <div class="space-y-5">
        <h1
          class="text-6xl flex justify-center dark:text-white text-gray-800 font-bold">
          STATS OF TRUCKERSMP
        </h1>
        <p class="dark:text-gray-500 max-w-6xl mx-auto text-gray-800">
            TruckersMP Data
        </p>
        <div class="flex gap-5 justify-center">
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            {{ current.current_players }}
            <p class="dark:text-gray-500 max-w-2xl mx-auto text-gray-800">
              Truckers Online
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            {{ current.ets2_current_players }}
            <p class="dark:text-gray-500 max-w-2xl mx-auto text-gray-800">
              Truckers in Europe
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            {{ current.ats_current_players }}
            <p class="dark:text-gray-500 max-w-2xl mx-auto text-gray-800">
              Truckers in the USA
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            0.2.5.1.3
            <p class="dark:text-gray-500 max-w-2xl mx-auto text-gray-800">
              Current Version
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            1.0.0.4
            <p class="dark:text-gray-500 max-w-2xl mx-auto text-gray-800">
              Launcher Version
            </p>
          </span>
        </div>
      </div>
    </div>
    <div class="container lg:max-w-8xl mx-auto text-center mt-10">
      <div class="space-y-5">
        <div class="flex gap-5 justify-center">
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            1.49.2.15s
            <p class="dark:text-gray-500 max-w-3xl mx-auto text-gray-800">
              ETS2 Supported Version
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            1.49.3.2s 
            <p class="dark:text-gray-500 max-w-3xl mx-auto text-gray-800">
              ATS Supported Version
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            {{ current.game_time }}
            <p class="dark:text-gray-500 max-w-3xl mx-auto text-gray-800">
              Current Game Time
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            {{ current.registered_players }}
            <p class="dark:text-gray-500 max-w-3xl mx-auto text-gray-800">
              Registered Truckers
            </p>
          </span>
          <span class="bg-white border dark:bg-gray-800 dark:border-gray-700 rounded dark:text-white py-2 px-10">
            17,524
            <p class="dark:text-gray-500 max-w-3xl mx-auto text-gray-800">
              All-time peak<br>
              4 years ago
            </p>
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="mt-2 lg:flex block lg:gap-2">
      <div class="mt-2 bg-white dark:bg-gray-800 p-0 w-full rounded-md box-border border dark:border-gray-700">
        <div class="head p-5">
          <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200"> {{ current.current_players }} </h2>
          <p class="text-gray-400 font-lexend font-normal"> Current Player </p>
           <span class="float-right">
            <h2 class="text-green-500 -mt-12 flex">
              <span class="mr-2"> 27.9% </span>
              <span>
                <Icon icon="akar-icons:arrow-up" />
              </span>
            </h2>
          </span>
        </div>
        <div class="wrapper-chart mt-5 pr-2 pl-2">
          <apexchart width="100%" height="380" type="bar" :options="optionsBar" :series="seriesBar"></apexchart>
          <br />
          <hr />
          <div class="footer p-5">
            <div class="wrapper-button flex justify-between mt-3">
              <select name="" id="" class="dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-700 border max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400">
                <option value="">Last 7 days</option>
              </select>
              <button class="uppercase border-b border-red-600 text-red-600"> Product Report </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 bg-white dark:bg-gray-800 p-0 w-full rounded-md box-border border dark:border-gray-700">
        <div class="head p-5">
          <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200"> 5,355 </h2>
          <p class="text-gray-400 font-lexend font-normal">Visitor this week</p>
          <span class="float-right">
            <h2 class="text-green-500 -mt-12 flex">
              <span class="mr-2"> 47.9% </span>
              <span>
                <Icon icon="akar-icons:arrow-up" />
              </span>
            </h2>
          </span>
        </div>
        <div class="wrapper-chart mt-5">
          <apexchart width="100%" height="380" type="area" :options="optionsVisitor" :series="seriesVisitor"></apexchart>
          <br />
          <hr />
          <div class="footer p-5">
            <div class="wrapper-button flex justify-between mt-3">
              <select name="" id="" class="dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-700 border max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400">
                <option value="">Last 7 days</option>
              </select>
              <button class="uppercase border-b border-red-600 text-red-600"> Vistor Report </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 bg-white dark:bg-gray-800 p-0 w-full rounded-md box-border border dark:border-gray-700">
        <div class="head p-5">
          <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200"> 475 </h2>
          <p class="text-gray-400 font-lexend font-normal"> User signups this week </p>
        </div>
        <div class="wrapper-chart mt-5">
          <apexchart width="100%" height="380" type="pie" :options="optionsDonut" :series="seriesDonut"></apexchart>
          <div class="p-3"></div>
          <br />
          <hr />
          <div class="footer p-5">
            <div class="wrapper-button flex justify-between mt-3">
              <select name="" id="" class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400">
                <option value="">Last 7 years</option>
              </select>
              <button class="uppercase border-b border-red-600 text-red-600"> User Report </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border border dark:border-gray-700">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200"> Latest Transactions </h2>
      <p class="text-gray-400 font-lexend font-normal"> This is a list of latest transactions </p>
      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="uppercase px-6 py-3"> Transaction </th>
              <th scope="col" class="uppercase px-6 py-3"> Date & Time </th>
              <th scope="col" class="uppercase px-6 py-3"> Amount </th>
              <th scope="col" class="uppercase px-6 py-3"> Status </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50" v-for="items in tableTransaction" :key="items.transaction">
              <td class="px-6 py-4">
                {{ items.transaction }}
              </td>
              <td class="px-6 py-4">
                {{ items.datetime }}
              </td>
              <td class="px-6 py-4">
                {{ items.amount }}
              </td>
              <td class="px-6 py-4">
                <span class="text-green-800 bg-green-300 px-3 py-1 rounded-md" v-if="items.statusTransaction == 'completed'">
                  {{ items.statusTransaction }}
                </span>
                <span class="text-purple-800 bg-purple-300 px-3 py-1 rounded-md" v-else-if="items.statusTransaction == 'progress'">
                  {{ items.statusTransaction }}
                </span>
                <span class="text-red-800 bg-red-300 px-3 py-1 rounded-md" v-else>
                  {{ items.statusTransaction }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="wrapper-button flex justify-between mt-3">
        <select name="" id="" class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400">
          <option value="">Last 7 years</option>
        </select>
        <button class="uppercase border-b border-red-600 text-red-600"> Transaction Report </button>
      </div>
    </div> -->
  </div>
</template>
<script>
  // @ is an alias to /src
  import {
    Icon
  } from "@iconify/vue";
  import Modal from "@/components/modal.vue";
  import Alert from "@/components/Alert.vue";
  import axios from 'axios';
  export default {
    name: "Dashboard",
    data() {
      return {
      websites: [
        { name: 'WEBSITE', url: '/WEBSITE', status: 'Unknown' },
        { name: 'FORUM', url: '/FORUM', status: 'Unknown' },
        { name: 'API', url: '/API', status: 'Unknown' },
        { name: 'MAP', url: '/MAP', status: 'Unknown' },
      ],
        servers: [],
        overall: [],
        status: [],
        area: [],
        current: [],
        // for more guide apexchart.js
        // https://apexcharts.com/docs/chart-types/line-chart/
        // chart data area
        optionsArea: {
          xaxis: {
            categories: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
          },
          fontFamily: "Segoe UI, sans-serif",
          stroke: {
            curve: "straight",
          },
          chart: {
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          markers: {
            size: 0,
          },
          yaxis: {
            show: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 0,
              opacityFrom: 0.2,
              opacityTo: 0.5,
              stops: [0, 90, 100],
            },
          },
        },
        chart: {
          fontFamily: "lexend, sans-serif",
        },
        seriesArea: [{
          name: "Revenue",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        }, {
          name: "Revenue (Previous period)",
          data: [20, 34, 45, 55, 79, 87, 90, 98],
        }, ],
        optionsBar: {
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          colors: ["#4f46e5", "#DC2626"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
        },
        seriesBar: [{
          name: "Product 1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        }, {
          name: "Product 2",
          data: [20, 34, 45, 55, 79, 87, 90, 98],
        }, ],
        optionsVisitor: {
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          colors: ["#4f46e5"],
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 0,
              opacityFrom: 0,
              opacityTo: 0.3,
              stops: [0, 90, 100],
            },
          },
          stroke: {
            curve: "smooth",
          },
        },
        seriesVisitor: [{
          name: "Visitor ",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        }, ],
        optionsDonut: {
          chart: {
            type: "donut",
          },
          legend: false,
          dataLabels: {
            enabled: false,
          },
          labels: ["admin", "SuperAdmin", "User", "Costumer"],
        },
        seriesDonut: [20, 15, 63, 83],
        tableTransaction: [{
          transaction: "Payment from Ethan Roger",
          datetime: "Apr 22, 2022",
          amount: "Rp.450.000",
          statusTransaction: "completed",
        }, {
          transaction: "Payment from Taylor neal",
          datetime: "May 2, 2022",
          amount: "Rp.250.000",
          statusTransaction: "completed",
        }, {
          transaction: "Payment from Tobi Ferreira",
          datetime: "May 5, 2022",
          amount: "Rp.150.000",
          statusTransaction: "progress",
        }, {
          transaction: "Payment failed from #046577",
          datetime: "May 5, 2022",
          amount: "Rp.180.000",
          statusTransaction: "cancelled",
        }, ],
      };
      // end chart data line
    },
    components: {
      Icon,
      Alert,
      Modal,
    },
  //   watch: {
  //   offlineServers: {
  //     handler(newOfflineServers) {
  //       if (newOfflineServers.length > 0) {
  //         this.sendAlertToDiscord(newOfflineServers);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
    computed: {
    // Use a computed property to filter offline servers
    offlineServers() {
      return this.servers.filter(server => !server.online).map(server => server.name);
    },
    fullServers() {
      return this.servers.filter(server => server.players === server.maxplayers).map(server => server.name);
    },
  },
    mounted() {
      this.getServersData();
      this.getOverallData();
      this.getStatusData();
      // this.getAreaData();
      this.getCurrentData();
      this.checkWebsiteStatus();

    },
    methods: {
      test() {
        alert("clicked!");
      },
      getServersData() {
        // JSONP kullanarak API isteği yapın
        const script = document.createElement('script');
        script.src = 'https://api.truckersmp.com/v2/servers?callback=processData';
  
        // JSONP yanıtını işleyecek işlevi tanımlayın
        window.processData = (data) => {
          this.servers = data.response;
          // JSONP işlemi tamamlandığında, işlevi temizleyin
          delete window.processData;
          script.remove();
        };
  
        document.body.appendChild(script);
      },
      getOverallData() {
      // Fetch overall data
      fetch('https://stats.truckersmp.com/api/v4/overall.json')
        .then(response => response.json())
        .then(data => {
          // Update the component data with the received data
          this.overall = {
            players: data.data.reduce((sum, server) => sum + server.players, 0),
            maxPlayers: data.data.reduce((sum, server) => sum + server.max_players, 0),
            data: data.data,
            color: data.color
          };
        })
        .catch(error => {
          console.error('Error fetching TruckersMP overall data:', error);
        });
    },
    async getCurrentData() {
      try {
        const response = await fetch('https://stats.truckersmp.com/api/v4/current.json');
        const data = await response.json();
        this.current = data.current;
      } catch (error) {
        console.error('Error fetching TruckersMP current data:', error);
      }
    },
    async checkWebsiteStatus() {
      for (const website of this.websites) {
        try {
          const start = performance.now();
          const response = await fetch(website.url);
          const end = performance.now();
          const responseTime = end - start;

          if (response.ok) {
            if (responseTime < 1000) {
              website.status = 'Online';
            } else {
              website.status = 'Unstable';
            }
          } else {
            website.status = 'Unstable';
          }
        } catch (error) {
          website.status = 'Offline';
        }
      }
    },
    // async sendAlertToDiscord(offlineServers) {
    //   const webhookUrl = 'https://discord.com/api/webhooks/1188590029098975362/WDS_JflkjLFduu3uVBtTjyzbnL8-4Cy17ocjmxrVKuMZtA2ITiJztEH-D12QxS4Pd9oz';
    //   const payload = {
    //     content: `Offline servers: ${offlineServers.join(', ')}`,
    //   };

    //   try {
    //     await axios.post(webhookUrl, payload);
    //     console.log('Alert sent to Discord');
    //   } catch (error) {
    //     console.error('Error sending alert to Discord:', error);
    //   }
    // },
    // getAreaData() {
    //       // Fetch area data
    //       fetch('https://tracker.ets2map.com/v3/area?x1=-35742&y1=1875&x2=-4022&y2=-16862&server=2')
    //         .then(response => response.json())
    //         .then(data => {
    //           // Update the component data with the received area data
    //           this.area = data.Data;
    //         })
    //         .catch(error => {
    //           console.error('Error fetching ETS2 map area data:', error);
    //         });
    // },
    getStatusData() {
      // Fetch status data
      fetch('https://stats.truckersmp.com/api/v4/status.json')
        .then(response => response.json())
        .then(data => {
          // Update the component data with the received data
          this.status.servers = data.servers;

          // Update the percent and arrow based on matching server.id and status._id
          this.updatePercentAndArrow();
        })
        .catch(error => {
          console.error('Error fetching TruckersMP status data:', error);
        });
    },
    updatePercentAndArrow() {
      // Update the percent and arrow based on matching server.id and status._id
      this.servers.forEach(server => {
        const matchingStatus = this.status.servers.find(status => status._id === server.id);
        if (matchingStatus) {
          // Update the percent and arrow properties for the server
          server.percent = matchingStatus.percent;
          server.arrow = matchingStatus.arrow;
        }
      });
    },
  },
  };
</script>