<template>
  <div class="dashboard p-4">
    
    <img
    class="max-w-sm rounded-t-lg"
    style="
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    "
    src="https://piratevtc.com/assets/css/hub/PirateHubLogin.png"
    alt=""
  />
    <h2 class="uppercase text-2xl text-center dark:text-white text-gray-800 font-semibold">PIRATE LOGISTICS EVENTS</h2>
    <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
    <!-- grid wrapper card -->
    <div  class="wrapper-card grid lg:grid-cols-4 grid-cols-2 md:grid-cols-2 gap-2 mt-5">
      <!-- card  -->
      <div v-if="upcomingEvents.length > 0">
        <div v-for="event in upcomingEvents.slice(0, 1)" :key="event.id" class="wrapper-button w-full box-border mt-4">
          <div v-if="event.vtc.id === 52488">
            <div class="card max-w-sm bg-white rounded-lg border border-gray-200 border dark:border-gray-700 dark:bg-gray-800 dark:border-gray-700">
              <a>
                <img class="rounded-t-lg" :src="event.banner" alt="" />
              </a>
              <div class="p-5">
                <a href="#">
                  <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {{ event.name }} "Just testing {{ event.vtc.id }} "
                  </h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
                </p>
              </div>
            </div>
          </div>
          <h2 
          class="dark:text-white text-gray-800 text-center font-regular" 
          v-else>
            Pirate is currently not hosting any events.
          </h2>
        </div>
      </div>
      <!-- end card --> 
    </div>
    <!-- start card -->
    <h2 class="uppercase text-2xl text-center dark:text-white text-gray-800 font-semibold">TRUCKERSMP FEATURED EVENTS</h2>
    <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">

    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-2 md:grid-cols-2 gap-2 mt-5">
      <div v-for="event in featuredEvents" :key="event.id" class="wrapper-button w-full box-border mt-4">
        <div
          class="card max-w-sm bg-white rounded-lg border border-gray-200 border dark:border-gray-700-md dark:bg-gray-800 dark:border-gray-700"
        >
          <a v-if="event.banner !== null" href="#">
            <img
              class="rounded-t-lg"
              :src="event.banner"
              alt=""
            />
          </a>
          <a v-else href="#">
            <img
              class="rounded-t-lg"
              style="max-width: %50;
              height: auto;"
              src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
              alt=""
            />
          </a>
          <Modal 
          :title="`Event Details: ${event.name} 🎉`" 
          btnText="📝" 
          :backdrop="false">
            <template v-slot:body>
              <div>
                <a v-if="event.banner !== null" href="#">
                  <img
                    class="rounded-t-lg w-full"
                    :src="event.banner"
                    alt=""
                  />
                </a>
                <a v-else href="#">
                  <img
                    class="rounded-t-lg w-full"
                    style="max-width: 100%; height: auto;"
                    src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                    alt=""
                  />
                </a>
                <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
                MAP: 
                <a v-if="event.map !== null" href="#">
                  <img
                    class="rounded-t-lg w-full"
                    :src="event.map"
                    alt=""
                  />
                </a>
                <a v-else href="#">
                  <img
                    class="rounded-t-lg w-full"
                    style="max-width: 100%; height: auto;"
                    src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                    alt=""
                  />
                </a>
                <hr class="my-8 border-t border-gray-300 dark:border-gray-700">

                <div>
                  <p class="mb-4 text-gray-800 dark:text-white">
                    <strong>Event Name:</strong> {{ event.name }} 🎉<br>
                    <strong>Event Type:</strong> {{ event.event_type.name }} 🤝<br>
                    <strong>Game:</strong> {{ event.game }} 🎮<br>
                    <strong>Server:</strong> {{ event.server.name }} 🚛<br>
                    <strong>Language:</strong> {{ event.language }} 🌐<br>
                    <strong>Departure:</strong> {{ event.departure.location }}, {{ event.departure.city }} 🚀<br>
                    <strong>Arrival:</strong> {{ event.arrive.location }}, {{ event.arrive.city }} 📍<br>
                    <strong>Meetup Time:</strong> {{ event.meetup_at }} ⌚<br>
                    <strong>Start Time:</strong> {{ event.start_at }} ⏰<br>
                    <!-- Add other event details here -->
                  </p>
        
                  <p class="mb-4 text-gray-800 dark:text-white">
                    <!-- <strong>Percentage Full:</strong> {{ ((event.attendances.confirmed / event.attendances.vtcs) * 100).toFixed(2) }}% 📊<br> -->
                    <strong>Players Confirmed:</strong> {{ event.attendances.confirmed }} 👥<br>
                    <strong>Players Unsure:</strong> {{ event.attendances.unsure }} 🤷‍♂️<br>
                    <strong>VTCs Confirmed:</strong> {{ event.attendances.vtcs }} 🚛<br>
                    <!-- Add other attendance details here -->
                  </p>

                  <p class="mb-4 text-gray-800 dark:text-white">
                    <strong>VTC Page:</strong> {{ event.external_link }} 🌐<br>
                    <!-- Add other attendance details here -->
                  </p>
        
                  <p class="mb-4 text-gray-800 dark:text-white">
                    <strong>ProMods:</strong>
                    <span v-if="event.game !== 'ATS'" :class="{ 'text-green-500': event.promods, 'text-red-500': !event.promods }">
                      {{ event.promods ? '&nbsp;Enabled' : '&nbsp;Disabled' }}
                    </span> 🗺️<br>
                    <!-- Add other event-specific details here -->
                  </p>
        
                  <div class="mb-8">
                    <h3 class="mb-2 text-lg font-semibold text-gray-800 dark:text-white">Event Website:</h3>
                    <a v-if="event.url" :href="`https://truckersmp.com/${event.url}`" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">
                      Event Page 
                    </a>
                    <span v-else class="text-gray-500">No website provided</span>
                  </div>
        
                  <ol class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <li class="relative mb-6">
                      <div class="flex items-center">
                        <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"/>
                        </svg>
                        </div>
                        <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                      </div>
                      <div class="mt-3 sm:pe-8">
                        <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Created At:</h3>
                        <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.created_at }}</time>
                      </div>
                    </li>
                    <li class="relative mb-6">
                      <div class="flex items-center">
                        <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2ZM2 18V7h6.7l.4-.409A4.309 4.309 0 0 1 15.753 7H18v11H2Z"/>
                            <path d="M8.139 10.411 5.289 13.3A1 1 0 0 0 5 14v2a1 1 0 0 0 1 1h2a1 1 0 0 0 .7-.288l2.886-2.851-3.447-3.45ZM14 8a2.463 2.463 0 0 0-3.484 0l-.971.983 3.468 3.468.987-.971A2.463 2.463 0 0 0 14 8Z"/>
                        </svg>
                        </div>
                      </div>
                      <div class="mt-3 sm:pe-8">
                        <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Updated At:</h3>
                        <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.updated_at }}</time>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </template>
          </Modal>
          <div class="p-5">
            <a href="#">
              <h5
                class="text-center mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
              >
              {{ event.name }}
              </h5>
            </a>
            <p  class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              ID: {{ event.id }}<br>
              Starting Date: {{ event.start_at }} <br>
              Game: {{ event.game }}<br>
              Event Type: {{ event.event_type.name }}<br>
              <span v-if="event.vtc.name !== null">
               VTC: {{ event.vtc.name }}<br>
              </span>
              Server: {{ event.server.name }}
            </p>
            <div>
            <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Confirmed: {{ event.attendances.confirmed }}</span>
            <span style="float: left;" class="mr-2 bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Unsure: {{ event.attendances.unsure }}</span>
            <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">VTCs: {{ event.attendances.vtcs }}</span>

            <br>
          </div>
            <a
            :href="`https://truckersmp.com${event.url}`" target="_blank" rel="noopener noreferrer"
              class="mt-2 inline-flex items-center py-2 px-24 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
               View&nbsp;On&nbsp;TruckersMP
              <!-- <svg
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg> -->
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->
            <!-- start card -->
            <br>
            <h2 class="uppercase text-2xl text-center dark:text-white text-gray-800 font-semibold">TRUCKERSMP STARTED EVENTS </h2>
            <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
        
            <div class="wrapper-card grid lg:grid-cols-4 grid-cols-2 md:grid-cols-2 gap-2 mt-5">
              <div v-for="event in nowEvents" :key="event.id" class="wrapper-button w-full box-border mt-4">
                <div
                  class="card max-w-sm bg-white rounded-lg border border-gray-200 border dark:border-gray-700-md dark:bg-gray-800 dark:border-gray-700"
                >
                  <a v-if="event.banner !== null" href="#">
                    <img
                      class="rounded-t-lg"
                      :src="event.banner"
                      alt=""
                    />
                  </a>
                  <a v-else href="#">
                    <img
                      class="rounded-t-lg"
                      style="max-width: %50;
                      height: auto;"
                      src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                      alt=""
                    />
                  </a>
                  <Modal 
                  :title="`Event Details: ${event.name} 🎉`" 
                  btnText="📝" 
                  :backdrop="false">
                    <template v-slot:body>
                      <div>
                        <a v-if="event.banner !== null" href="#">
                          <img
                            class="rounded-t-lg w-full"
                            :src="event.banner"
                            alt=""
                          />
                        </a>
                        <a v-else href="#">
                          <img
                            class="rounded-t-lg w-full"
                            style="max-width: 100%; height: auto;"
                            src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                            alt=""
                          />
                        </a>
                        <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
                        MAP: 
                        <a v-if="event.map !== null" href="#">
                          <img
                            class="rounded-t-lg w-full"
                            :src="event.map"
                            alt=""
                          />
                        </a>
                        <a v-else href="#">
                          <img
                            class="rounded-t-lg w-full"
                            style="max-width: 100%; height: auto;"
                            src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                            alt=""
                          />
                        </a>
                        <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
        
                        <div>
                          <p class="mb-4 text-gray-800 dark:text-white">
                            <strong>Event Name:</strong> {{ event.name }} 🎉<br>
                            <strong>Event Type:</strong> {{ event.event_type.name }} 🤝<br>
                            <strong>Game:</strong> {{ event.game }} 🎮<br>
                            <strong>Server:</strong> {{ event.server.name }} 🚛<br>
                            <strong>Language:</strong> {{ event.language }} 🌐<br>
                            <strong>Departure:</strong> {{ event.departure.location }}, {{ event.departure.city }} 🚀<br>
                            <strong>Arrival:</strong> {{ event.arrive.location }}, {{ event.arrive.city }} 📍<br>
                            <strong>Meetup Time:</strong> {{ event.meetup_at }} ⌚<br>
                            <strong>Start Time:</strong> {{ event.start_at }} ⏰<br>
                            <!-- Add other event details here -->
                          </p>
                
                          <p class="mb-4 text-gray-800 dark:text-white">
                            <!-- <strong>Percentage Full:</strong> {{ ((event.attendances.confirmed / event.attendances.vtcs) * 100).toFixed(2) }}% 📊<br> -->
                            <strong>Players Confirmed:</strong> {{ event.attendances.confirmed }} 👥<br>
                            <strong>Players Unsure:</strong> {{ event.attendances.unsure }} 🤷‍♂️<br>
                            <strong>VTCs Confirmed:</strong> {{ event.attendances.vtcs }} 🚛<br>
                            <!-- Add other attendance details here -->
                          </p>
        
                          <p class="mb-4 text-gray-800 dark:text-white">
                            <strong>VTC Page:</strong> {{ event.external_link }} 🌐<br>
                            <!-- Add other attendance details here -->
                          </p>
                
                          <p class="mb-4 text-gray-800 dark:text-white">
                            <strong>ProMods:</strong>
                            <span v-if="event.game !== 'ATS'" :class="{ 'text-green-500': event.promods, 'text-red-500': !event.promods }">
                              {{ event.promods ? '&nbsp;Enabled' : '&nbsp;Disabled' }}
                            </span> 🗺️<br>
                            <!-- Add other event-specific details here -->
                          </p>
                
                          <div class="mb-8">
                            <h3 class="mb-2 text-lg font-semibold text-gray-800 dark:text-white">Event Website:</h3>
                            <a v-if="event.url" :href="`https://truckersmp.com/${event.url}`" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">
                              Event Page 
                            </a>
                            <span v-else class="text-gray-500">No website provided</span>
                          </div>
                
                          <ol class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <li class="relative mb-6">
                              <div class="flex items-center">
                                <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"/>
                                </svg>
                                </div>
                                <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                              </div>
                              <div class="mt-3 sm:pe-8">
                                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Created At:</h3>
                                <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.created_at }}</time>
                              </div>
                            </li>
                            <li class="relative mb-6">
                              <div class="flex items-center">
                                <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M18 2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2ZM2 18V7h6.7l.4-.409A4.309 4.309 0 0 1 15.753 7H18v11H2Z"/>
                                    <path d="M8.139 10.411 5.289 13.3A1 1 0 0 0 5 14v2a1 1 0 0 0 1 1h2a1 1 0 0 0 .7-.288l2.886-2.851-3.447-3.45ZM14 8a2.463 2.463 0 0 0-3.484 0l-.971.983 3.468 3.468.987-.971A2.463 2.463 0 0 0 14 8Z"/>
                                </svg>
                                </div>
                              </div>
                              <div class="mt-3 sm:pe-8">
                                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Updated At:</h3>
                                <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.updated_at }}</time>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </template>
                  </Modal>
                  <div class="p-5">
                    <a href="#">
                      <h5
                        class="text-center mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                      >
                      {{ event.name }}
                      </h5>
                    </a>
                    <p  class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                      ID: {{ event.id }}<br>
                      Starting Date: {{ event.start_at }} <br>
                      Game: {{ event.game }}<br>
                      Event Type: {{ event.event_type.name }}<br>
                      <span v-if="event.vtc.name !== null">
                       VTC: {{ event.vtc.name }}<br>
                      </span>
                      Server: {{ event.server.name }}
                    </p>
                    <div>
                    <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Confirmed: {{ event.attendances.confirmed }}</span>
                    <span style="float: left;" class="mr-2 bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Unsure: {{ event.attendances.unsure }}</span>
                    <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">VTCs: {{ event.attendances.vtcs }}</span>
        
                    <br>
                  </div>
                    <a
                    :href="`https://truckersmp.com${event.url}`" target="_blank" rel="noopener noreferrer"
                      class="mt-2 inline-flex items-center py-2 px-24 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                       View&nbsp;On&nbsp;TruckersMP
                      <!-- <svg
                        class="ml-2 -mr-1 w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg> -->
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
        <!-- start card -->
        <br>
        <h2 class="uppercase text-2xl text-center dark:text-white text-gray-800 font-semibold">TRUCKERSMP Events in the Next 24 Hours</h2>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
    
        <div class="wrapper-card grid lg:grid-cols-4 grid-cols-2 md:grid-cols-2 gap-2 mt-5">
          <div v-for="event in todayEvents" :key="event.id" class="wrapper-button w-full box-border mt-4">
            <div
              class="card max-w-sm bg-white rounded-lg border border-gray-200 border dark:border-gray-700-md dark:bg-gray-800 dark:border-gray-700"
            >
              <a v-if="event.banner !== null" href="#">
                <img
                  class="rounded-t-lg"
                  :src="event.banner"
                  alt=""
                />
              </a>
              <a v-else href="#">
                <img
                  class="rounded-t-lg"
                  style="max-width: %50;
                  height: auto;"
                  src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                  alt=""
                />
              </a>
              <Modal 
              :title="`Event Details: ${event.name} 🎉`" 
              btnText="📝" 
              :backdrop="false">
                <template v-slot:body>
                  <div>
                    <a v-if="event.banner !== null" href="#">
                      <img
                        class="rounded-t-lg w-full"
                        :src="event.banner"
                        alt=""
                      />
                    </a>
                    <a v-else href="#">
                      <img
                        class="rounded-t-lg w-full"
                        style="max-width: 100%; height: auto;"
                        src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                        alt=""
                      />
                    </a>
                    <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
                    MAP: 
                    <a v-if="event.map !== null" href="#">
                      <img
                        class="rounded-t-lg w-full"
                        :src="event.map"
                        alt=""
                      />
                    </a>
                    <a v-else href="#">
                      <img
                        class="rounded-t-lg w-full"
                        style="max-width: 100%; height: auto;"
                        src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                        alt=""
                      />
                    </a>
                    <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
    
                    <div>
                      <p class="mb-4 text-gray-800 dark:text-white">
                        <strong>Event Name:</strong> {{ event.name }} 🎉<br>
                        <strong>Event Type:</strong> {{ event.event_type.name }} 🤝<br>
                        <strong>Game:</strong> {{ event.game }} 🎮<br>
                        <strong>Server:</strong> {{ event.server.name }} 🚛<br>
                        <strong>Language:</strong> {{ event.language }} 🌐<br>
                        <strong>Departure:</strong> {{ event.departure.location }}, {{ event.departure.city }} 🚀<br>
                        <strong>Arrival:</strong> {{ event.arrive.location }}, {{ event.arrive.city }} 📍<br>
                        <strong>Meetup Time:</strong> {{ event.meetup_at }} ⌚<br>
                        <strong>Start Time:</strong> {{ event.start_at }} ⏰<br>
                        <!-- Add other event details here -->
                      </p>
            
                      <p class="mb-4 text-gray-800 dark:text-white">
                        <!-- <strong>Percentage Full:</strong> {{ ((event.attendances.confirmed / event.attendances.vtcs) * 100).toFixed(2) }}% 📊<br> -->
                        <strong>Players Confirmed:</strong> {{ event.attendances.confirmed }} 👥<br>
                        <strong>Players Unsure:</strong> {{ event.attendances.unsure }} 🤷‍♂️<br>
                        <strong>VTCs Confirmed:</strong> {{ event.attendances.vtcs }} 🚛<br>
                        <!-- Add other attendance details here -->
                      </p>
    
                      <p class="mb-4 text-gray-800 dark:text-white">
                        <strong>VTC Page:</strong> {{ event.external_link }} 🌐<br>
                        <!-- Add other attendance details here -->
                      </p>
            
                      <p class="mb-4 text-gray-800 dark:text-white">
                        <strong>ProMods:</strong>
                        <span v-if="event.game !== 'ATS'" :class="{ 'text-green-500': event.promods, 'text-red-500': !event.promods }">
                          {{ event.promods ? '&nbsp;Enabled' : '&nbsp;Disabled' }}
                        </span> 🗺️<br>
                        <!-- Add other event-specific details here -->
                      </p>
            
                      <div class="mb-8">
                        <h3 class="mb-2 text-lg font-semibold text-gray-800 dark:text-white">Event Website:</h3>
                        <a v-if="event.url" :href="`https://truckersmp.com/${event.url}`" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">
                          Event Page 
                        </a>
                        <span v-else class="text-gray-500">No website provided</span>
                      </div>
            
                      <ol class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <li class="relative mb-6">
                          <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"/>
                            </svg>
                            </div>
                            <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                          </div>
                          <div class="mt-3 sm:pe-8">
                            <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Created At:</h3>
                            <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.created_at }}</time>
                          </div>
                        </li>
                        <li class="relative mb-6">
                          <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M18 2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2ZM2 18V7h6.7l.4-.409A4.309 4.309 0 0 1 15.753 7H18v11H2Z"/>
                                <path d="M8.139 10.411 5.289 13.3A1 1 0 0 0 5 14v2a1 1 0 0 0 1 1h2a1 1 0 0 0 .7-.288l2.886-2.851-3.447-3.45ZM14 8a2.463 2.463 0 0 0-3.484 0l-.971.983 3.468 3.468.987-.971A2.463 2.463 0 0 0 14 8Z"/>
                            </svg>
                            </div>
                          </div>
                          <div class="mt-3 sm:pe-8">
                            <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Updated At:</h3>
                            <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.updated_at }}</time>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </template>
              </Modal>
              <div class="p-5">
                <a href="#">
                  <h5
                    class="text-center mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                  >
                  {{ event.name }}
                  </h5>
                </a>
                <p  class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  ID: {{ event.id }}<br>
                  Starting Date: {{ event.start_at }} <br>
                  Game: {{ event.game }}<br>
                  Event Type: {{ event.event_type.name }}<br>
                  <span v-if="event.vtc.name !== null">
                   VTC: {{ event.vtc.name }}<br>
                  </span>
                  Server: {{ event.server.name }}
                </p>
                <div>
                <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Confirmed: {{ event.attendances.confirmed }}</span>
                <span style="float: left;" class="mr-2 bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Unsure: {{ event.attendances.unsure }}</span>
                <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">VTCs: {{ event.attendances.vtcs }}</span>
    
                <br>
              </div>
                <a
                :href="`https://truckersmp.com${event.url}`" target="_blank" rel="noopener noreferrer"
                  class="mt-2 inline-flex items-center py-2 px-24 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                   View&nbsp;On&nbsp;TruckersMP
                  <!-- <svg
                    class="ml-2 -mr-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg> -->
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
                <!-- start card -->
                <br>
                <h2 class="uppercase text-2xl text-center dark:text-white text-gray-800 font-semibold">TRUCKERSMP upcoming events</h2>
                <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
            
                <div class="wrapper-card grid lg:grid-cols-4 grid-cols-2 md:grid-cols-2 gap-2 mt-5">
                  <div v-for="event in upcomingEvents" :key="event.id" class="wrapper-button w-full box-border mt-4">
                    <div
                      class="card max-w-sm bg-white rounded-lg border border-gray-200 border dark:border-gray-700-md dark:bg-gray-800 dark:border-gray-700"
                    >
                      <a v-if="event.banner !== null" href="#">
                        <img
                          class="rounded-t-lg"
                          :src="event.banner"
                          alt=""
                        />
                      </a>
                      <a v-else href="#">
                        <img
                          class="rounded-t-lg"
                          style="max-width: %50;
                          height: auto;"
                          src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                          alt=""
                        />
                      </a>
                      <Modal 
                      :title="`Event Details: ${event.name} 🎉`" 
                      btnText="📝" 
                      :backdrop="false">
                        <template v-slot:body>
                          <div>
                            <a v-if="event.banner !== null" href="#">
                              <img
                                class="rounded-t-lg w-full"
                                :src="event.banner"
                                alt=""
                              />
                            </a>
                            <a v-else href="#">
                              <img
                                class="rounded-t-lg w-full"
                                style="max-width: 100%; height: auto;"
                                src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                                alt=""
                              />
                            </a>
                            <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
                            MAP: 
                            <a v-if="event.map !== null" href="#">
                              <img
                                class="rounded-t-lg w-full"
                                :src="event.map"
                                alt=""
                              />
                            </a>
                            <a v-else href="#">
                              <img
                                class="rounded-t-lg w-full"
                                style="max-width: 100%; height: auto;"
                                src="https://static.truckersmp.com/images/bg/ats-ets.jpg"
                                alt=""
                              />
                            </a>
                            <hr class="my-8 border-t border-gray-300 dark:border-gray-700">
            
                            <div>
                              <p class="mb-4 text-gray-800 dark:text-white">
                                <strong>Event Name:</strong> {{ event.name }} 🎉<br>
                                <strong>Event Type:</strong> {{ event.event_type.name }} 🤝<br>
                                <strong>Game:</strong> {{ event.game }} 🎮<br>
                                <strong>Server:</strong> {{ event.server.name }} 🚛<br>
                                <strong>Language:</strong> {{ event.language }} 🌐<br>
                                <strong>Departure:</strong> {{ event.departure.location }}, {{ event.departure.city }} 🚀<br>
                                <strong>Arrival:</strong> {{ event.arrive.location }}, {{ event.arrive.city }} 📍<br>
                                <strong>Meetup Time:</strong> {{ event.meetup_at }} ⌚<br>
                                <strong>Start Time:</strong> {{ event.start_at }} ⏰<br>
                                <!-- Add other event details here -->
                              </p>
                    
                              <p class="mb-4 text-gray-800 dark:text-white">
                                <!-- <strong>Percentage Full:</strong> {{ ((event.attendances.confirmed / event.attendances.vtcs) * 100).toFixed(2) }}% 📊<br> -->
                                <strong>Players Confirmed:</strong> {{ event.attendances.confirmed }} 👥<br>
                                <strong>Players Unsure:</strong> {{ event.attendances.unsure }} 🤷‍♂️<br>
                                <strong>VTCs Confirmed:</strong> {{ event.attendances.vtcs }} 🚛<br>
                                <!-- Add other attendance details here -->
                              </p>
            
                              <p class="mb-4 text-gray-800 dark:text-white">
                                <strong>VTC Page:</strong> {{ event.external_link }} 🌐<br>
                                <!-- Add other attendance details here -->
                              </p>
                    
                              <p class="mb-4 text-gray-800 dark:text-white">
                                <strong>ProMods:</strong>
                                <span v-if="event.game !== 'ATS'" :class="{ 'text-green-500': event.promods, 'text-red-500': !event.promods }">
                                  {{ event.promods ? '&nbsp;Enabled' : '&nbsp;Disabled' }}
                                </span> 🗺️<br>
                                <!-- Add other event-specific details here -->
                              </p>
                    
                              <div class="mb-8">
                                <h3 class="mb-2 text-lg font-semibold text-gray-800 dark:text-white">Event Website:</h3>
                                <a v-if="event.url" :href="`https://truckersmp.com/${event.url}`" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">
                                  Event Page 
                                </a>
                                <span v-else class="text-gray-500">No website provided</span>
                              </div>
                    
                              <ol class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <li class="relative mb-6">
                                  <div class="flex items-center">
                                    <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                      <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"/>
                                    </svg>
                                    </div>
                                    <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                                  </div>
                                  <div class="mt-3 sm:pe-8">
                                    <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Created At:</h3>
                                    <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.created_at }}</time>
                                  </div>
                                </li>
                                <li class="relative mb-6">
                                  <div class="flex items-center">
                                    <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-gray-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                      <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M18 2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2ZM2 18V7h6.7l.4-.409A4.309 4.309 0 0 1 15.753 7H18v11H2Z"/>
                                        <path d="M8.139 10.411 5.289 13.3A1 1 0 0 0 5 14v2a1 1 0 0 0 1 1h2a1 1 0 0 0 .7-.288l2.886-2.851-3.447-3.45ZM14 8a2.463 2.463 0 0 0-3.484 0l-.971.983 3.468 3.468.987-.971A2.463 2.463 0 0 0 14 8Z"/>
                                    </svg>
                                    </div>
                                  </div>
                                  <div class="mt-3 sm:pe-8">
                                    <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Updated At:</h3>
                                    <time class="block mb-2 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{{ event.updated_at }}</time>
                                  </div>
                                </li>
                              </ol>
                            </div>
                          </div>
                        </template>
                      </Modal>
                      <div class="p-5">
                        <a href="#">
                          <h5
                            class="text-center mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                          >
                          {{ event.name }}
                          </h5>
                        </a>
                        <p  class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                          ID: {{ event.id }}<br>
                          Starting Date: {{ event.start_at }} <br>
                          Game: {{ event.game }}<br>
                          Event Type: {{ event.event_type.name }}<br>
                          <span v-if="event.vtc.name !== null">
                           VTC: {{ event.vtc.name }}<br>
                          </span>
                          Server: {{ event.server.name }}
                        </p>
                        <div>
                        <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Confirmed: {{ event.attendances.confirmed }}</span>
                        <span style="float: left;" class="mr-2 bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Unsure: {{ event.attendances.unsure }}</span>
                        <span style="float: left;" class="mr-2 bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">VTCs: {{ event.attendances.vtcs }}</span>
            
                        <br>
                      </div>
                        <a
                        :href="`https://truckersmp.com${event.url}`" target="_blank" rel="noopener noreferrer"
                          class="mt-2 inline-flex items-center py-2 px-24 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                           View&nbsp;On&nbsp;TruckersMP
                          <!-- <svg
                            class="ml-2 -mr-1 w-4 h-4"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg> -->
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card -->
    <!-- <div>
      <h2>Featured Events</h2>
      <ul>
        <li v-for="event in featuredEvents" :key="event.id">{{ event.name }}</li>
      </ul>
  
      <h2>Today's Events</h2>
      <ul>
        <li v-for="event in todayEvents" :key="event.id">{{ event.name }}</li>
      </ul>
  
      <h2>Now Events</h2>
      <ul>
        <li v-for="event in nowEvents" :key="event.id">{{ event.name }}</li>
      </ul>
  
      <h2>Upcoming Events</h2>
      <ul>
        <li v-for="event in upcomingEvents" :key="event.id">{{ event.name }}</li>
      </ul>
    </div> -->
    <!-- <div>
      <h1>TruckersMP Events</h1>
      <ul>
        <li v-for="event in featuredEvents" :key="event.id">
          <div>
            <strong>ID:</strong> {{ event.id }}
          </div>
          <div>
            <strong>Name:</strong> {{ event.name }}
          </div>
          <div>
            <strong>Event Type:</strong> {{ event.event_type.name }}
          </div>
          <div>
            <strong>Game:</strong> {{ event.game }}
          </div>
          <div>
            <strong>Server:</strong> {{ event.server.name }}
          </div>
          <div>
            <strong>Language:</strong> {{ event.language }}
          </div>
          <div>
            <strong>Departure Location:</strong> {{ event.departure.location }}
          </div>
          <div>
            <strong>Arrival Location:</strong> {{ event.arrive.location }}
          </div>
          <div>
            <strong>Meetup Time:</strong> {{ event.meetup_at }}
          </div>
          <div>
            <strong>Start Time:</strong> {{ event.start_at }}
          </div>
          <div>
            <strong>Banner:</strong> {{ event.banner }}
          </div>
          <div>
            <strong>Map:</strong> {{ event.map }}
          </div>
          <div>
            <strong>Rule:</strong> {{ event.rule }}
          </div>
          <div>
            <strong>Voice Link:</strong> {{ event.voice_link }}
          </div>
          <div>
            <strong>External Link:</strong> {{ event.external_link }}
          </div>
          <div>
            <strong>Featured:</strong> {{ event.featured }}
          </div>
          <div>
            <strong>VTC ID:</strong> {{ event.vtc.id }}
          </div>
          <div>
            <strong>VTC Name:</strong> {{ event.vtc.name }}
          </div>
          <div>
            <strong>User ID:</strong> {{ event.user.id }}
          </div>
          <div>
            <strong>Username:</strong> {{ event.user.username }}
          </div>
          <div>
            <strong>Attendances Confirmed:</strong> {{ event.attendances.confirmed }}
          </div>
          <div>
            <strong>Attendances Unsure:</strong> {{ event.attendances.unsure }}
          </div>
          <div>
            <strong>VTCs:</strong> {{ event.attendances.vtcs }}
          </div>
          <div>
            <strong>DLCs:</strong> {{ event.dlcs }}
          </div>
          <div>
            <strong>URL:</strong> {{ event.url }}
          </div>
          <div>
            <strong>Created At:</strong> {{ event.created_at }}
          </div>
          <div>
            <strong>Updated At:</strong> {{ event.updated_at }}
          </div>

          <div v-html="event.description"></div>
  
          <hr />
        </li>
      </ul>
    </div> -->
    </div>
</template>
<script>
  // @ is an alias to /src
  import {
    Icon
  } from "@iconify/vue";
  import Modal from "@/components/modal.vue";
  import Alert from "@/components/Alert.vue";
  import axios from 'axios';
  export default {
    name: "Dashboard",
    data() {
      return {
      websites: [
        { name: 'WEBSITE', url: '/WEBSITE', status: 'Unknown' },
        { name: 'FORUM', url: '/FORUM', status: 'Unknown' },
        { name: 'API', url: '/API', status: 'Unknown' },
        { name: 'MAP', url: '/MAP', status: 'Unknown' },
      ],
        servers: [],
        overall: [],
        status: [],
        featuredEvents: [],
      todayEvents: [],
      nowEvents: [],
      upcomingEvents: [],
        area: [],
        current: [],

      };
      // end chart data line
    },
    components: {
      Icon,
      Alert,
      Modal,
    },
  //   watch: {
  //   offlineServers: {
  //     handler(newOfflineServers) {
  //       if (newOfflineServers.length > 0) {
  //         this.sendAlertToDiscord(newOfflineServers);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
    computed: {
    // Use a computed property to filter offline servers
    offlineServers() {
      return this.servers.filter(server => !server.online).map(server => server.name);
    },
    fullServers() {
      return this.servers.filter(server => server.players === server.maxplayers).map(server => server.name);
    },
  },
    mounted() {
      this.getServersData();
      this.getOverallData();
      this.getStatusData();
      // this.getAreaData();
      this.getCurrentData();
      this.checkWebsiteStatus();
      this.fetchEventData();

    },
    methods: {
      test() {
        alert("clicked!");
      },
      async fetchEventData() {
      try {
        const response = await fetch('https://tmpstats.4fast.lol:3000/api/v2/events');
        const responseData = await response.json();

        // Update the data properties with the received data
        this.featuredEvents = responseData.response.featured;
        this.todayEvents = responseData.response.today;
        this.nowEvents = responseData.response.now;
        this.upcomingEvents = responseData.response.upcoming;
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    },
      getServersData() {
        // JSONP kullanarak API isteği yapın
        const script = document.createElement('script');
        script.src = 'https://api.truckersmp.com/v2/servers?callback=processData';
  
        // JSONP yanıtını işleyecek işlevi tanımlayın
        window.processData = (data) => {
          this.servers = data.response;
          // JSONP işlemi tamamlandığında, işlevi temizleyin
          delete window.processData;
          script.remove();
        };
  
        document.body.appendChild(script);
      },
      getOverallData() {
      // Fetch overall data
      fetch('https://stats.truckersmp.com/api/v4/overall.json')
        .then(response => response.json())
        .then(data => {
          // Update the component data with the received data
          this.overall = {
            players: data.data.reduce((sum, server) => sum + server.players, 0),
            maxPlayers: data.data.reduce((sum, server) => sum + server.max_players, 0),
            data: data.data,
            color: data.color
          };
        })
        .catch(error => {
          console.error('Error fetching TruckersMP overall data:', error);
        });
    },
    async getCurrentData() {
      try {
        const response = await fetch('https://stats.truckersmp.com/api/v4/current.json');
        const data = await response.json();
        this.current = data.current;
      } catch (error) {
        console.error('Error fetching TruckersMP current data:', error);
      }
    },
    async checkWebsiteStatus() {
      for (const website of this.websites) {
        try {
          const start = performance.now();
          const response = await fetch(website.url);
          const end = performance.now();
          const responseTime = end - start;

          if (response.ok) {
            if (responseTime < 1000) {
              website.status = 'Online';
            } else {
              website.status = 'Unstable';
            }
          } else {
            website.status = 'Unstable';
          }
        } catch (error) {
          website.status = 'Offline';
        }
      }
    },
    // async sendAlertToDiscord(offlineServers) {
    //   const webhookUrl = 'https://discord.com/api/webhooks/1188590029098975362/WDS_JflkjLFduu3uVBtTjyzbnL8-4Cy17ocjmxrVKuMZtA2ITiJztEH-D12QxS4Pd9oz';
    //   const payload = {
    //     content: `Offline servers: ${offlineServers.join(', ')}`,
    //   };

    //   try {
    //     await axios.post(webhookUrl, payload);
    //     console.log('Alert sent to Discord');
    //   } catch (error) {
    //     console.error('Error sending alert to Discord:', error);
    //   }
    // },
    // getAreaData() {
    //       // Fetch area data
    //       fetch('https://tracker.ets2map.com/v3/area?x1=-35742&y1=1875&x2=-4022&y2=-16862&server=2')
    //         .then(response => response.json())
    //         .then(data => {
    //           // Update the component data with the received area data
    //           this.area = data.Data;
    //         })
    //         .catch(error => {
    //           console.error('Error fetching ETS2 map area data:', error);
    //         });
    // },
    getStatusData() {
      // Fetch status data
      fetch('https://stats.truckersmp.com/api/v4/status.json')
        .then(response => response.json())
        .then(data => {
          // Update the component data with the received data
          this.status.servers = data.servers;

          // Update the percent and arrow based on matching server.id and status._id
          this.updatePercentAndArrow();
        })
        .catch(error => {
          console.error('Error fetching TruckersMP status data:', error);
        });
    },
    updatePercentAndArrow() {
      // Update the percent and arrow based on matching server.id and status._id
      this.servers.forEach(server => {
        const matchingStatus = this.status.servers.find(status => status._id === server.id);
        if (matchingStatus) {
          // Update the percent and arrow properties for the server
          server.percent = matchingStatus.percent;
          server.arrow = matchingStatus.arrow;
        }
      });
    },
  },
  };
</script>